import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Whatsapp = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      <div>
        <button
          onClick={scrollToTop}
          className={`fixed z-50 bottom-5 right-5 lg:right-8 w-16 h-16 bg-gray-800 rounded-full flex justify-center items-center cursor-pointer transition-opacity ${isVisible ? "opacity-100 animate__animated animate__fadeInUp" : "opacity-0"}`}
        >
          <FontAwesomeIcon icon={faArrowUp} alt="Ícone de seta para cima" className="w-6 h-6 text-white" />
        </button>
        <a
          href="https://api.whatsapp.com/send?phone=554891093046"
          target="_blank"
          rel="noopener noreferrer"
          title="Entrar em contato via WhatsApp"
          className={`fixed z-50 bg-green-500 hover:bg-green-600 right-6 w-16 h-16 lg:w-20 lg:h-20 rounded-full flex justify-center items-center cursor-pointer transition-opacity ${isVisible ? "bottom-24 animate__animated animate__fadeInUp" : "bottom-5 animate__animated animate__fadeInDown"}`}
        >
          <FontAwesomeIcon icon={faWhatsapp} alt="Ícone do WhatsApp" className="w-6 h-6 lg:w-10 lg:h-10 text-white" />
        </a>
      </div>
    </>
  );
};

export default Whatsapp;
