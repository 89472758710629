



import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/custom.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import Development from './pages/Development';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Course from './pages/Course';
import NotFound from './pages/NotFound';

createRoot(document.getElementById('root')).render(
  <Router>
    <Routes>

      <Route path='*' element={<Development />} />
      <Route path='/curso' element={<Course />} />

      {/*
      <Route path='/home' element={<Home />} />
      <Route path='/sobre-mim' element={<About />} />
      <Route path='/contato' element={<Contact />} />
      <Route path='*' element={<NotFound />} />
      */}
    </Routes>
  </Router>
);