import React, { useState } from "react";
import logo from '../assets/logo.png';

const Header = () => {

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="w-full bg-primary">
      <nav className="w-3/4 mx-auto py-6 flex justify-between items-center bg-primary">
        <div>
          <a href={"../"} className="inline-block">
            <img className="w-32 lg:w-48 mr-4 lg:mr-8" alt="Logo" src={logo}/>
          </a>
        </div>
        <div className="md:hidden relative">
          <button
            className="text-light focus:outline-none p-2"
            onClick={toggleMobileMenu}
          >
            <i className="fa-solid fa-bars text-xl"></i>
          </button>
        </div>
        <ul className={`md:flex space-x-4 hidden md:block items-center`}>
          <li>
            <a href='../' className="mx-2 lg:mx-4 text-light font-primary">Home</a>
          </li>
          <li>
            <a href='../sobre-mim' className="mx-2 lg:mx-4 text-light font-primary">Sobre mim</a>
          </li>
          <li>
            <a href='../contato' className="mx-2 lg:mx-4 text-light font-primary">Contato</a>
          </li>
          <li>
            <a  href="https://api.whatsapp.com/send?phone=554891093046" target="_blank">
              <button className="bg-tertiary text-light font-primary text-lg py-2 px-4">Vamos conversar?</button>
            </a>
          </li>
        </ul>
      </nav>
      {isMobileMenuOpen && (
        <ul className="absolute bg-primary top-26 right-0 z-20 mobile-menu w-48 rounded-b-xl grid">
          <li className="p-3">
            <a href='../' className="mx-2 lg:mx-4 text-light font-primary">Home</a>
          </li>
          <li className="p-3">
            <a href='../sobre-mim' className="mx-2 lg:mx-4 text-light font-primary">Sobre mim</a>
          </li>
          <li className="p-3">
            <a href='../contato' className="mx-2 lg:mx-4 text-light font-primary">Contato</a>
          </li>
          <li className="px-3 pt-3 pb-5">
            <a  href="https://api.whatsapp.com/send?phone=554891093046" target="_blank">
              <button href="https://api.whatsapp.com/send?phone=554891093046" className="bg-tertiary text-light font-primary text-lg py-2 px-4" target="_blank">Vamos conversar?</button>
            </a>
          </li>
        </ul>
      )}
    </header>
  );
};

export default Header;