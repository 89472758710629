import React, { useState } from 'react';
import curso from '../assets/cursos/curso.png';
import rh from '../assets/cursos/rh.png';
import lider from '../assets/cursos/lider.png';
import treinamento from '../assets/cursos/treinamento.png';
import terapeuta from '../assets/cursos/terapeuta.png';
import empreendedor from '../assets/cursos/empreendedor.png';
import coach from '../assets/cursos/coach.png';
import ok from '../assets/cursos/ok.png';
import depoimento from '../assets/depoimentos/depoimento.mp4';
import depoimento1 from '../assets/depoimentos/depoimento1.mp4';

const Course = () => {
  const [showAllBenefits, setShowAllBenefits] = useState(false);

  function toggleShowAllBenefits() {
    setShowAllBenefits(!showAllBenefits);
  }

  return (
    <>
      <div className="w-full bg-light py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col xl:flex-row items-center">
            <div className="w-full xl:w-1/2">
              <img
                src={curso}
                alt="Analista Comportamental DISC"
                className="w-full h-auto rounded-lg float-right"
              />
            </div>
            <div className="w-full xl:w-1/2 mt-8 xl:mt-0 xl:ml-8">
              <h1 className="text-3xl font-bold text-gray-800 mb-4">Formação de Analista Comportamental DISC</h1>
              <p className="text-lg text-gray-600 mb-6">
                Com nossa Formação de Analista Comportamental DISC, você adquire conhecimento profundo da metodologia, desde a teoria até a prática, e se destaca como um Analista Comportamental certificado. Desenvolva habilidades práticas para realizar Recrutamento, Seleção e Desenvolvimento de talentos de maneira muito mais eficiente!
              </p>
              <button className='p-4 w-full bg-secondary rounded-xl text-white'>Quero participar</button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-tertiary py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className='text-center'>
            <p className="text-3xl text-gray-800 mb-4 inline">Quais os benefícios da </p><p className="text-3xl font-bold text-gray-800 mb-4 inline">Formação de Analista Comportamental DISC</p><p className="text-3xl text-gray-800 mb-4 inline">?</p>
            <div className="flex flex-col xl:flex-row items-center xl:space-x-4 mt-16 space-y-4">
              <div className="w-full xl:w-1/2 space-y-4">
                <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                  <img src={ok} alt="ok" className="w-16 mx-4" />
                  <p className='text-light'>Identificação de perfis comportamentais com DISC.</p>
                </div>
                <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                  <img src={ok} alt="ok" className="w-16 mx-4" />
                  <p className='text-light'>Melhoria da produtividade e eficiência dos colaboradores.</p>
                </div>
                { showAllBenefits &&
                  <>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Certificação Internacional para qualificação profissional.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Desenvolvimento consciente de pessoas e lideranças.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Dados estratégicos para RH e treinamentos.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Colocação correta para reduzir rotatividade.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Recrutamento e seleção mais eficientes.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Relatórios DISC para PDIs personalizados.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Decisões estratégicas baseadas em dados.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Desenvolvimento pessoal e autoconhecimento.</p>
                    </div>
                  </>
                }
              </div>
              <div className="w-full xl:w-1/2 space-y-4">
                <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                  <img src={ok} alt="ok" className="w-16 mx-4" />
                  <p className='text-light'>Recrutamento mais assertivo e eficiente.</p>
                </div>
                <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                  <img src={ok} alt="ok" className="w-16 mx-4" />
                  <p className='text-light'>Desenvolvimento de pessoas com segurança.</p>
                </div>
                { showAllBenefits &&
                  <>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Treinamentos mais eficazes.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Melhoria nas relações interpessoais.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Promoção de uma cultura de alta performance.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Colaboradores mais engajados e comprometidos.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Desenvolvimento de habilidades e melhoria de gaps.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Elevação do desempenho da equipe de vendas.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Liderança mais segura e assertiva.</p>
                    </div>
                    <div className="flex items-center p-4 h-32 rounded-3xl shadow bg-primary">
                      <img src={ok} alt="ok" className="w-16 mx-4" />
                      <p className='text-light'>Terapias mais eficientes para psicólogos e terapeutas.</p>
                    </div>
                  </>
                }
              </div>
            </div>
            <button onClick={toggleShowAllBenefits} className='mt-8 py-4 px-16 bg-primary text-white rounded-lg'>
              {showAllBenefits ? 'Ver menos benefícios ' : 'Ver mais benefícios '}
            </button>
          </div>
        </div>
      </div>
      <div className="w-full bg-light py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div>
            <h1 className="text-3xl font-bold text-center text-gray-800">Para quem é esse curso?</h1>
          </div>
          <div className="flex flex-col xl:flex-row items-center xl:space-x-4 mt-16">
            <div className="w-full lg:w-1/2 xl:w-1/3 px-8 mb-8 xl:mb-0">
              <img src={terapeuta} alt="ok" className="w-2/3 mx-auto mb-8" />
              <h3 className="text-2xl font-bold">Terapeutas e Mentores</h3>
              <ul className='list-disc mt-8'>
                <li>Entender os padrões de comportamento e motivações dos clientes para um processo terapêutico mais eficaz.</li>
                <li>Adaptar as técnicas terapêuticas de acordo com os perfis comportamentais dos indivíduos.</li>
                <li>Identificar e superar crenças limitantes que impactam o comportamento.</li>
                <li>Promover e manter relacionamentos saudáveis e equilibrados.</li>
                <li>Desenvolver habilidades de comunicação e resolução de conflitos nos clientes.</li>
              </ul>
            </div>
            <div className="w-full lg:w-1/2 xl:w-1/3 px-8 mb-8 xl:mb-0">
              <img src={empreendedor} alt="ok" className="w-2/3 mx-auto mb-8" />
              <h3 className="text-2xl font-bold">Empresários e Líderes</h3>
              <ul className='list-disc mt-8'>
                <li>Adequar estilos de comunicação e liderança aos diversos perfis comportamentais.</li>
                <li>Montar equipes de alto desempenho com habilidades complementares.</li>
                <li>Identificar e desenvolver líderes internos que atendam às necessidades da empresa.</li>
                <li>Gerenciar conflitos levando em consideração as diferenças comportamentais.</li>
                <li>Criar estratégias de motivação personalizadas para cada membro da equipe.</li>
              </ul>
            </div>
            <div className="w-full lg:w-1/2 xl:w-1/3 px-8 mb-8 xl:mb-0">
              <img src={treinamento} alt="ok" className="w-2/3 mx-auto mb-8" />
              <h3 className="text-2xl font-bold">Formadores e Instrutores</h3>
              <ul className='list-disc mt-8'>
                <li>Elaborar programas de treinamento personalizados para diferentes perfis comportamentais.</li>
                <li>Adaptar métodos de ensino conforme as características dos participantes.</li>
                <li>Promover uma aprendizagem significativa considerando os diferentes estilos de aprendizado.</li>
                <li>Identificar e abordar as necessidades específicas de desenvolvimento de cada perfil.</li>
                <li>Avaliar a eficácia e impacto dos programas de treinamento com base em características comportamentais.</li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col xl:flex-row items-center xl:space-x-4 xl:mt-16">
            <div className="w-full lg:w-1/2 xl:w-1/3 px-8 mb-8 xl:mb-0">
              <img src={rh} alt="ok" className="w-2/3 mx-auto mb-8" />
              <h3 className="text-2xl font-bold">Recursos Humanos</h3>
              <ul className='list-disc mt-8'>
                <li>Compreender profundamente os perfis e comportamentos dos colaboradores.</li>
                <li>Identificar e alocar talentos de forma estratégica nas equipes.</li>
                <li>Otimizar processos seletivos para alinhar aos perfis desejados.</li>
                <li>Melhorar avaliações de desempenho com foco em características comportamentais.</li>
                <li>Elaborar planos de carreira e estratégias de crescimento personalizadas.</li>
              </ul>
            </div>
            <div className="w-full lg:w-1/2 xl:w-1/3 px-8 mb-8 xl:mb-0">
              <img src={lider} alt="ok" className="w-2/3 mx-auto mb-8" />
              <h3 className="text-2xl font-bold">Desenvolvimento Profissional</h3>
              <ul className='list-disc mt-8'>
                <li>Adquirir uma habilidade única e valiosa no mercado de trabalho.</li>
                <li>Ampliar as oportunidades de crescimento e reconhecimento na carreira.</li>
                <li>Aumentar a eficiência e produtividade em diversos contextos.</li>
                <li>Expandir a compreensão do comportamento humano em diversas áreas de atuação.</li>
                <li>Desenvolver competências em liderança, comunicação e gestão de pessoas.</li>
                <li>Melhorar a empregabilidade e competitividade no mercado de trabalho.</li>
              </ul>
            </div>
            <div className="w-full lg:w-1/2 xl:w-1/3 px-8 mb-8 xl:mb-0">
              <img src={coach} alt="ok" className="w-2/3 mx-auto mb-8" />
              <h3 className="text-2xl font-bold">Coaches Profissionais</h3>
              <ul className='list-disc mt-8'>
                <li>Enriquecer a prática de coaching com uma compreensão aprofundada dos perfis comportamentais.</li>
                <li>Ajustar abordagens de coaching de acordo com os traços dos clientes.</li>
                <li>Facilitar a autodescoberta e o desenvolvimento pessoal com insights detalhados.</li>
                <li>Fomentar relações profissionais saudáveis e produtivas.</li>
                <li>Ajudar na definição de metas e estratégias de desenvolvimento personalizadas.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-secondary py-16">
        <h3 className="text-3xl text-center font-bold text-light mb-8">Depoimentos de outros cursos</h3>
        <div className="flex flex-wrap max-w-7xl mx-auto">
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
            <div className="rounded-lg overflow-hidden">
              <video className="w-full" controls>
                <source src={depoimento} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
            <div className="rounded-lg overflow-hidden">
              <video className="w-full" controls>
                <source src={depoimento1} type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="w-full sm:w-1/2 lg:w-1/3 p-4">
            <a href="https://api.whatsapp.com/send?phone=554891093046" target="_blank">
              <div className="bg-light rounded-lg p-6 flex items-center justify-center h-full">
                  <p className="text-center text-gray-800 font-medium">Deixe seu depoimento</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Course;
