import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Whatsapp from '../components/Whatsapp';
import Courses from '../components/Course';
import Footer from '../components/Footer';

const Course = () => {
  return (
    <>
      <Header />
      <Whatsapp />
      <Courses />
      <Footer />
    </>
  );
};


export default Course;