import React, { useEffect } from 'react';
import Logo from '../assets/logo.png'
import Whatsapp from '../components/Whatsapp';

const Development = () => {

  return (
      <>
        <div className="flex flex-col min-h-screen justify-center items-center text-center bg-primary">
          <Whatsapp />
          <div className="my-32">
            <div className="animate__animated animate__fadeInDown animate__delay-1s">
              <img className="w-48 lg: w-64 mx-auto mb-2" alt="Logo" src={Logo}/>
              <h1 className="text-2xl lg:text-6xl font-bold text-main mt-4 text-tertiary">Em Construção</h1>
              <h1 className="text-xl lg:text-4xl font-bold text-light">Estamos trabalhando nisso!</h1>
              <p className="text-light">Desculpe pelo transtorno, mas estamos fazendo melhorias. Volte em breve!</p>
            </div>
          </div>
        </div>
      </>
    );
};

export default Development;
